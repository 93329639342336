/* Carousel container */
.carousel-container {
    padding: 10px 0;
}

/* Padding for carousel items */
.carousel-item-padding-40-px {
    padding: 0px; /* Remove spacing between items to reduce gaps */
}

/* Make sure there's some padding for partial visibility */
.carousel .react-multi-carousel-item--partial {
    padding-left: 10px;
    padding-right: 10px;
}

/* For larger screens, reduce the padding further */
@media (min-width: 1024px) {
    .carousel-item-padding-40-px {
        padding: 0px;
    }
}

/* Optional: to apply a fade effect on the sides */
.carousel-container::before,
.carousel-container::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    pointer-events: none;
    z-index: 2;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff 100%);
}

.carousel-container::after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 100%);
}

