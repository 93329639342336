/* Card container */
.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 300px;
    max-height: 450px;
    position: relative;
}

/* Ensure image doesn't touch edges */
.image-wrapper {
    padding: 10px;
    background-color: #fff;
}

.card-image {
    width: 100%;
    height: 180px;
    object-fit: contain; 
    border-radius: 8px;
}

/* Content within the card */
.card-content {
    padding: 15px;
    text-align: center;
}

/* Title of the card */
.card-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

/* Description of the card */

.card-description.truncated {
    -webkit-line-clamp: 3;
}

.view-more {
    margin-top: 10px;
    color: #007BFF;
    cursor: pointer;
    font-weight: bold;
}

/* Overlay for dimming the background */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.badge {
    padding: 5px 12px;            /* Adds some space inside the badge */
    border: 2px solid #28a745;    /* Green border */
    border-radius: 20px;          /* Rounded corners */
    font-size: 0.875rem;          /* Font size */
    color: #28a745;               /* Green text color to match the border */
    background-color: white;      /* White background */
    display: inline-block;        /* Ensures the badge fits nicely with surrounding text */
    font-weight: 500;             /* Makes the text bold */
    text-transform: uppercase;    /* Converts the text to uppercase */
    margin-top: 10px;             /* Adds some space above the badge */
}


/* Expanded card styles */
.expanded-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    padding: 20px;
    z-index: 1001; /* Ensures the card is above the overlay */
}

/* Close icon */
.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    color: #007BFF;
}

/* Description of the card */
.card-description {
    font-size: 1rem;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limits to 3 lines */
    -webkit-box-orient: vertical;
}

.expanded-card .card-description {
    -webkit-line-clamp: unset; /* Allow the text to fully expand */
    display: block; /* Remove the box constraint */
    white-space: normal; /* Ensure text wraps normally */
    overflow: visible; /* Allow text to overflow normally */
}
