.skills {
  padding: 20px;
}

.skills-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Default to 1 column */
  gap: 20px;
}

.skill-card {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 5px;
}

.skill-rating {
  margin: 10px 0;
}

.progress-bar-container {
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 20px;
  width: 100%;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50; /* Green color for the progress bar */
  transition: width 0.3s ease-in-out;
  border-radius: 5px 0 0 5px;
}

.skill-tags {
  margin-top: 10px;
}

.skill-tag {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 0.9em;
}

@media (min-width: 600px) {
  .skills-list {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
}

@media (min-width: 900px) {
  .skills-list {
    grid-template-columns: repeat(4, 1fr); /* 4 columns on wide screens */
  }
}

