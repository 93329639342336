.contact-page {
    position: relative;
    /* background: url('/path-to-your-mountain-graphic.jpg') no-repeat center center fixed; */
    background-size: cover;
    min-height: 100vh;
    padding: 60px 20px;
    box-sizing: border-box; /* Ensure padding does not affect the overall size */
}

.contact-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 20px;
}

.contact-content {
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted effect */
    max-width: 1200px;
    width: 75%; /* Cover approximately 75% of the screen width */
    box-sizing: border-box; /* Ensure padding is included in the width */
    display: flex;
    align-items: flex-start;
    gap: 20px; /* Space between photo and text */
}
/* CV button styling */
.download-cv-button {
    background-color: #4caf50; /* Green color */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  box-sizing: border-box;
}

.download-cv-button:hover {
    background-color: #45a049; /* Darker green */
}
.photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-photo {
    width: 100%;
    max-width: 250px; /* Scale down the photo size */
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center text vertically */
    flex: 1; /* Allow the text section to take remaining space */
}

.contact-title {
    font-size: 28px; /* Adjusted font size for better scaling */
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.contact-details {
    display: flex;
    flex-direction: column;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px; /* Adjusted font size for better scaling */
    color: #333;
}

.contact-item i {
    font-size: 18px; /* Adjusted icon size */
    margin-right: 10px;
    color: #007bff;
}

.contact-link {
    color: #007bff;
    text-decoration: none;
}

.contact-link:hover {
    text-decoration: underline;
}

.social-icons {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

.social-icons a {
    font-size: 20px; /* Adjusted icon size */
    color: #333;
    transition: color 0.3s;
}


.social-icons a:hover {
    color: #007bff;
}

/* Responsive Design */
@media (max-width: 768px) {
    .contact-content {
        flex-direction: column; /* Stack photo and text vertically on mobile */
        width: 90%; /* Scale to a larger percentage on mobile */
    }

    .photo-container {
        display: none; /* Hide photo on mobile */
    }

    .contact-title {
        font-size: 24px; /* Adjust font size on mobile */
    }

    .contact-item {
        font-size: 12px; /* Adjust font size on mobile */
    }

    .contact-item i {
        font-size: 16px; /* Adjust icon size on mobile */
    }

    .social-icons a {
        font-size: 18px; /* Adjust icon size on mobile */
    }
}

