.internship-section {
    padding: 20px;
    background: #fff;
}

.internship-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px; /* Space between cards */
    justify-content: center; /* Centers cards horizontally */
}

.internship-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 350px; /* Limits the width of each card */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%; /* Full width of the grid item */
}

.internship-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
    text-align: center;
}

.company-name {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    background: linear-gradient(45deg, #ff6f61, #d83a56);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    padding: 10px;
    border-radius: 5px;
}

.date-range {
    font-size: 0.875rem;
    color: #777;
    display: block;
    margin: 5px 0 10px;
}

.company-description {
    font-size: 1rem;
    color: #444;
    line-height: 1.5;
}

/* Media query for large screens (4 cards per row) */
@media (min-width: 2151px) {
    .internship-grid {
        grid-template-columns: repeat(6, 1fr); /* Four cards per row */
    }
}


/* Media query for large screens (4 cards per row) */
@media ((min-width: 1024px) and (max-width: 2150px)) {
    .internship-grid {
        grid-template-columns: repeat(3, 1fr); /* Four cards per row */
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* Media query for medium screens (2 cards per row) */
@media (min-width: 768px) and (max-width: 1023px) {
    .internship-grid {
        grid-template-columns: repeat(2, 1fr); /* Two cards per row */
    }
}

/* Default (small screens) - 1 card per row */
@media (max-width: 767px) {
    .internship-grid {
        grid-template-columns: 1fr; /* One card per row */
        justify-content: center; /* Centers cards horizontally */
        display:flex;
        align-items:center;
    }
}

