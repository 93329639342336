.scroll-arrow-container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.scroll-arrow-container.visible {
    opacity: 0.6;
}

.scroll-arrow-container.hidden {
    opacity: 0;
}

.scroll-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.scroll-arrow i {
    font-size: 24px;
    color: #333;
}

.scroll-arrow p {
    margin-top: 8px;
    font-size: 14px;
    color: #333;
}

