.internship-card {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 350px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative; /* For positioning the role badge */
    width: 100%;
}

.internship-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.company-name {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    background: linear-gradient(45deg, #ff6f61, #d83a56);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0;
    padding: 10px;
    border-radius: 5px;
}

.role-badge {
    background: #4caf50;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 0.875rem;
    margin-top: 10px;
}

.date-range {
    font-size: 0.875rem;
    color: #777;
    display: block;
    margin: 5px 0 10px;
}

.company-description {
    font-size: 1rem;
    color: #444;
    line-height: 1.5;
    margin-top: 20px;
}

/* Media query for large screens */
@media (min-width: 1024px) {
    .internship-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Media query for medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
    .internship-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Default (small screens) */
@media (max-width: 767px) {
    .internship-grid {
        grid-template-columns: 1fr;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

