* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fff;
}
/* CV button styling */
/* Container for the header and button */
.header-container {
    position: relative;
    min-height: 100vh; /* Ensure the container covers the full viewport height */
}
.about-photo{
  max-width: 100%;
  height: auto;
}
@media (max-width: 600px) {
  .about-photo{
    width: 100px; /* Adjust image size for smaller screens */
  }
}

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f9f9f9; /* Assuming this is the background used elsewhere */
}

.App {
  display: flex;
}
/* General container styling */
.container {
  padding: 16px; /* Add padding around the container */
  max-width: 1200px; /* Optional: limit the container width */
  margin: 0 auto; /* Center the container */
}

/* Flexbox layout for image and text */
.image-text-wrapper {
  display: flex; /* Use flexbox for layout */
  align-items: flex-start; /* Align items to the top */
  gap: 24px; /* Space between image and text */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Responsive image styling */
.responsive-image {
  width: 40%; /* Set the image to be 40% of the container's width */
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure image doesn't exceed container's width */
  border-radius: 8px; /* Optional: rounded corners for the image */
  object-fit: cover; /* Ensures the image covers the area while maintaining aspect ratio */
  display: block; /* Ensure the image behaves as a block element */
  margin: 0 auto; /* Center the image horizontally */
}

@media(min-width: 1700px){
  .responsive-image {
    width: 450px;
  }
}

/* Text content styling */
.text-content {
  flex: 1; /* Allow the text to take remaining space */
  max-width: 100%; /* Ensure text container is responsive */
  font-family: 'Arial', sans-serif; /* Clean and modern font */
  font-size: 16px; /* Set font size for readability */
  line-height: 1.5; /* Improve readability with line height */
  color: #333; /* Dark grey color for text */
  text-align: left; /* Align text to the left */
}

/* Text heading styling */
.text-content h1 {
  font-size: 24px; /* Larger font size for headings */
  margin-top: 0; /* Align heading with the top of the image */
  margin-bottom: 12px; /* Add spacing below the heading */
  font-weight: bold; /* Make heading bold */
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .image-text-wrapper {
    flex-direction: column; /* Stack image and text vertically on small screens */
    align-items: center; /* Center align items horizontally on small screens */
  }

  .responsive-image {
    width: 70%; /* Adjust image size for smaller screens */
    max-width: 100%; /* Ensure image scales correctly on small screens */
    margin-bottom: 16px; /* Add space between the image and the text */
  }

  .text-content {
    text-align: justify; /* Ensure text remains left-aligned */
    padding: 0 5px; /* Add equal padding on both sides */
    width: 100%; /* Ensure text container fits within the screen */
    box-sizing: border-box; /* Include padding in the element's total width and height */
  }
}

/* Table of contents styling */
.toc {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px; /* Adjust width as needed */
  height: 100%;
  background-color: #f9f9f9;
  padding-top: 60px; /* Space from the top */
  display: flex;
  flex-direction: column;
  align-items: center; /* Horizontally center all content */
  justify-content: flex-start; /* Align items vertically to start */
  z-index: 999;
  transition: transform 0.3s ease;
}

.social-icons download-cv-icon {
    font-size: 20px; /* Adjusted icon size */
    color: #333;
    transition: color 0.3s;
}
.social-icons download-cv-button:hover {
    color: #007bff;
}


.contact-photo-sidebar {
  width: 100%;
  max-width: 150px; /* Scale down the photo size for better fit */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px; /* Add space below the image */
  object-fit: cover;
}

.toc-menu{
  display: flex;
  align-items: center;  /* Vertically centers the child div */
  justify-content: center; /* Horizontally centers the child div */
  height: 100vh; /* Full viewport height to demonstrate vertical alignment */
}

.toc ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.toc ul li {
  margin: 20px 0;
  text-align: left; /* Left align for better readability */
}

.toc ul li a {
  color: #333;
  text-decoration: none;
  font-size: 16px; /* Adjusted for the wider sidebar */
  padding: 10px 20px;
  display: block;
  border-radius: 8px;
  transition: background 0.3s, color 0.3s;
}

.toc ul li a:hover {
  background-color: #e0e0e0; /* Slight hover effect */
  color: #000;
}

/* Hamburger button styling */
.toc-button {
  display: none; /* Hidden on desktop */
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  cursor: pointer;
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

/* Hamburger icon */
.hamburger {
  font-size: 24px;
}

/* Content area styling */
.content {
  margin-left: 200px; /* Offset by the width of the toc */
  padding: 20px;
  width: calc(100% - 200px); /* Adjust content width */
  transition: margin-left 0.3s ease;
}

/* Responsive styling for mobile/tablet */
@media (max-width: 768px) {
  .toc {
    transform: translateX(-200px); /* Hidden by default */
  }

  .toc.open {
    transform: translateX(0);
  }

  .toc-button {
    display: block; /* Show on mobile */
  }

  .content {
    margin-left: 0;
    width: 100%;
  }
}




/* Header styles */
.Header {
    text-align: center;
    padding: 20px;
}

/* CV button container */
.cv-button-container {
    position: absolute;
    top: 66%; /* Approx 2/3 down the page */
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
}

/* CV button styling */
.cv-button {
    background-color: #4caf50; /* Green color */
    color: white;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.cv-button:hover {
    background-color: #45a049; /* Darker green */
}



/* Project grid layout */

.project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px; /* Adjust the spacing between cards */
    padding: 20px;
    max-width: 1200px; /* Optional: limit the width of the grid */
    margin: 0 auto; /* Center the grid on the page */
}

/* styles.css */

.info-cards-section {
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: auto;
}

.info-cards {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap; /* Allows cards to wrap onto new lines */
}

.info-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    padding: 20px;
    flex: 1;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    min-width: 280px; /* Ensures cards have a minimum width */
}

.info-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.info-card h2 {
    font-size: 1.5rem;
    color: #333;
    margin: 10px 0;
}

.info-card p {
    font-size: 1rem;
    color: #555;
}

.icon {
    font-size: 2.5rem;
    color: #d9534f;
    margin-bottom: 15px;
}

.skills-section {
  margin: 10% auto;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .info-cards {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .info-card {
        margin-bottom: 20px;
        width: 100%; /* Ensures cards take full width on small screens */
        max-width: 500px; /* Optional: limit the width of cards */
        align-items: center;
        justify-content: center;
    }
    
    .info-card:last-child {
        margin-bottom: 0; /* Remove margin from the last card */
    }

}

html.dark-mode {
  background-color: #000;
}
button:focus {
  outline: 1px solid #777 !important;
}
.lead {
  font-weight: 400;
}
.bg-danger {
  background-color: var(--primary-color) !important;
}
.Header {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: Montserrat, sans-serif;
}
.Header h1 {
  color: #000;
  text-align: center;
  font-size: 5.5rem;
  position: absolute;
}
.Header p {
  margin-top: 125px;
}
.particle {
  position: absolute!;
  margin: 0 !important;
  margin-top: -100vh !important;
}
.underline {
  text-decoration: underline;
  text-decoration-color: var(--primary-color);
}
.skills {
  padding: 3%;
}
.progress-block {
  padding: 10px 0;
}
.progress-block h2 {
  font-size: x-large;
}
.dark-mode {
  filter: invert(1) hue-rotate(180deg);
  -webkit-filter: invert(1) hue-rotate(180deg);
  -moz-filter: invert(1) hue-rotate(180deg);
}
.inverse-dark {
  filter: invert(1) hue-rotate(180deg);
  -webkit-filter: invert(1) hue-rotate(180deg);
  -moz-filter: invert(1) hue-rotate(180deg);
}
.effect2 {
  position: relative;
  width: 90%;
  background: #fff;
  padding: 5%;
  margin: 10% auto;
  border-bottom: solid 1px #777 !important;
}
.effect2:after,
.effect2:before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  /* -webkit-box-shadow: 0 15px 10px #777; */
  /* -moz-box-shadow: 0 15px 10px #777; */
  /* box-shadow: 0 15px 10px #777; */
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.effect2:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}
.Photo {
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
}
.Photo.inverse-dark {
  background-color: #000;
}
.about-text {
  width: 80%;
  padding: 2%;
}
.red-line {
  color: var(--primary-color);
  font-weight: 500;
}
.About-title-box {
  box-sizing: border-box;
  width: 80%;
  padding-bottom: 5px;
  border-bottom: solid 2px var(--primary-color);
  margin-bottom: 15px;
  justify-content: center;
}
.internship-section{
  margin: 10% auto;
}
.project-section{
  margin: 10% auto;
}
.topTitleBox {
  display:flex;
  justify-content: center;
}
.Project-title-box {
  box-sizing: border-box;
  width: 80%;
  padding-bottom: 5px;
  border-bottom: solid 2px var(--primary-color);
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}
.Internship-title-box {
  box-sizing: border-box;
  width: 80%;
  padding-bottom: 5px;
  border-bottom: solid 2px var(--primary-color);
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}
.skill__square {
  display: inline;
  margin: 15px 0 !important;
}
.skill__square i {
  font-size: 50px !important;
  color: #777 !important;
  height: 20px !important;
}
.skill__square svg {
  width: 35px !important;
  min-height: 20px !important;
  padding: 0;
  margin: 0;
  color: #777 !important;
}
.skill__square h6 {
  text-align: center;
  margin: auto;
  font-size: 1em;
  padding-top: 5px;
}
.skill__square i:hover,
.skill__square svg:hover {
  color: #ff4753 !important;
}
.third {
  width: 75%;
  margin: 10% auto;
  align-items: center;
  padding: 3%;
  justify-content: center;
}
.project-card {
  padding: 15px;
  margin-top: 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  border: 0.5px solid #eee;
}
.project-card h2 {
  color: var(--primary-color);
  margin-top: 10px;
  margin-left: 3%;
  font-weight: 500;
}
.project-card h2:hover {
  color: var(--hover-color);
  text-decoration: none;
}
.project-card h3 {
  text-align: left;
  font-size: 1rem;
  margin-left: 3%;
}
.image-project {
  height: 300px;
  width: auto;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer !important;
}
.img-pro {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.image-project:hover .img-pro {
  transform: scale(1.2);
}
.pp-head {
  text-align: center;
}
.pp-head-line {
  box-sizing: border-box;
  width: 30%;
  padding-bottom: 5px;
  border-bottom: solid 2px var(--primary-color);
  margin-bottom: 15px;
}
.pp-text {
  font-size: 1.5rem;
}
.parallax {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.git-form {
  width: 1160px;
  box-sizing: border-box;
  display: block;
  margin: 3rem 3rem 0 3rem;
  background-color: #fff;
  max-height: 80vh;
}
.git-head-div {
  box-sizing: border-box;
  padding-top: 5%;
  padding-bottom: 15px;
  width: 30%;
  border-bottom: 3px solid #000;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.git-cont {
  display: inline-block;
}
.half {
  display: inline-block;
  vertical-align: top;
}
.half form input,
textarea {
  width: 100%;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  outline: 0;
  box-shadow: none;
  border: 1px solid #777;
}
.half form textarea {
  padding-bottom: 45px;
}
.half form input:focus,
textarea:focus {
  border-radius: 5px;
  outline: 0;
  box-shadow: none;
  border: 1px solid var(--primary-color);
}
.half form button {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 2rem;
  color: #fff;
  background-color: var(--primary-color);
  box-shadow: none;
  outline: 0;
  border: none;
  margin-top: 20px;
  margin-left: 20px;
}
.half form button:hover {
  background-color: var(--hover-color);
}
.half form {
  padding: 3rem 1rem;
}
.half p {
  padding: 3rem 1rem 0 1rem;
  text-align: center;
}
.inline-button {
  display: inline;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
}
.git-cont .fab {
  vertical-align: bottom;
  padding: 10px;
  font-size: 30px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  color: var(--primary-color);
  transition: 0.5;
}
.git-cont .fab:hover {
  color: var(--hover-color);
}
.git-cont .fas {
  vertical-align: bottom;
  font-size: 40px;
  height: 30px;
  margin: 25px;
  text-align: center;
  text-decoration: none;
  color: #000;
  transition: 0.5;
}
.navbar {
  width: 100%;
  position: fixed;
  z-index: 1001;
  top: 0;
  box-shadow: 0 8px 6px -6px #646464;
}
.nav-link {
  color: #000 !important;
}
.is-current {
  color: var(--primary-color);
  text-decoration: underline;
  text-decoration-style: solid 3px;
  text-decoration-color: var(--primary-color);
}
.Copy {
  color: #fff !important;
  font-weight: 500;
  padding-top: 2%;
}
.project-name {
  text-decoration: none;
}
.gtp {
  position: fixed;
  left: 92%;
  height: 100px;
  top: 82%;
  z-index: 1002;
  cursor: crosshair;
}
.see {
  background-color: var(--primary-color);
  color: #fff;
  border: #000;
  outline: #000;
  position: absolute;
  padding: 5px 15px;
  padding-bottom: 7px;
  border-radius: 3px;
  left: 44%;
  top: 90%;
}
.fa-eye {
  color: #fff;
  font-size: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 7px;
}
.see:active {
  outline: 0;
}
.see:hover {
  background-color: var(--hover-color);
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  left: 44.5%;
  bottom: 46%;
}
.Header p {
  margin-top: 40px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #000;
}
input:focus + .slider {
  box-shadow: 0 0 1px #000;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.line-1 {
  position: relative;
  top: 10%;
  width: 12em;
  margin: 0 auto;
  border-right: 2px solid rgba(25, 25, 25, 0.75);
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  font-family: "Anonymous Pro", monospace !important;
}
.anim-typewriter {
  animation: typewriter 4s steps(25) 1s 1 normal both,
    blinkTextCursor 0.5s steps(25) infinite normal;
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 14em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(25, 25, 25, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}

.parallax {
    background-image: url("https://www.benedictshort.com/contact_backdrop.jpg");
  }
::-moz-selection {
  color: #fff;
  background: var(--primary-color);
}
::selection {
  color: #fff;
  background: var(--primary-color);
}
@media (max-width: 1300px) {
  .effect2 {
    width: 100%;
  }
  
}
@media (max-width: 650px)
{
.parallax {
    background-image: url("https://www.benedictshort.com/contact_backdrop2.jpg");
  }
}

@media (max-width: 800px) {
  
  .lead {
    font-weight: 300;
  }
  .Header h1 {
    font-size: 2.5rem;
  }
  .line-1 {
    font-size: 80%;
    top: 5.5%;
  }
  .underline {
    text-decoration-color: (0, 0, 0, 0.1);
  }
  .about-text {
    width: 100%;
    text-align: center;
  }
  .red-line::before {
    bottom: 590px;
  }
  .effect2 {
    width: 100%;
  }
  .skills {
    padding: 10% 5%;
  }
  .third {
    width: 100%;
  }
  .project-card {
    width: 100% !important;
  }
  .image-project {
    width: 100% !important;
  }
  .third {
    padding: 5%;
  }
  .pp-head-line {
    width: 70%;
  }
  .git-form {
    width: 90%;
    padding: 5px;
    padding-bottom: 35px;
  }
  .git-head-div {
    width: 75%;
  }
  .switch {
    bottom: 48%;
    left: 35%;
  }
  .half form input,
  textarea {
    margin: 5px 0;
    padding: 10px;
  }
  .half p {
    display: none;
  }
  .gtp {
    left: 80%;
    top: 87%;
    height: 75px;
  }
  .Copy {
    display: none;
  }
  .see {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 1rem;
  }
  .fa-eye {
    font-size: 20px;
  }
  .half form button {
    margin-left: auto;
    margin-right: auto;
  }



}

